import React, { useState } from 'react';
import Hub from '../../pages/Hub';
import ProductLayout from '../../pages/ProductLayout'; // Assume this is the component for products
import { IconMap } from '../../config/IconMap';
import Tooltip from './Tooltip';
import { Handle } from 'reactflow';

const CustomNode = ({ data, addNewCustomNode, app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const topText = {
    position: 'absolute',
    top: '-2rem',
    left: '10rem',
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
  };

  const sideIcon = {
    position: 'absolute',
    top: '50%', // Center vertically
    right: '-5rem', // Adjust the left position as needed
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
  };

  const sideMenu = {
    zIndex: '99',
    position: 'absolute',
    top: '70%',
    transform: 'translate(-50%, -50%)', // Center horizontally
    cursor: 'pointer',
    minWidth: '20rem',
    minHeight: '10rem',
  };

  return (
    <div className="canvas mockup-phone border-primary shadow hover:shadow-md">
      <div className="text-sm text-gray-600" style={topText}>{data.asset_type}</div>
      <div className="camera"></div>

      <div className="display bg-gray-50">
        <div className="content artboard phone w-[375px] h-[667px]">
          {/* Conditionally render Hub or ProductLayout based on the type */}
          {data.asset_type === 'App' ? (
            <Hub previewMode={true} previewData={app_metadata} />
          ) : data.asset_type === 'Product' ? (
            <ProductLayout previewMode={true} previewData={data.app_metadata} />
          ) : (
            <p>Unknown type</p>
          )}
        </div>
      </div>

      {/* Toggle Icon */}
      <div
        className={`plus-icon border border-gray-300 ${showTooltip ? 'bg-gray-200 text-gray-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-200'}`}
        style={sideIcon}
        onClick={toggleTooltip}
      >
        {React.createElement(
          showTooltip ? IconMap['XMarkIcon'] : IconMap['SquaresPlusIcon'],
          { className: 'block h-6 w-6 m-1', 'aria-hidden': 'true' }
        )}
      </div>

      {/* Tooltip that appears on click */}
      {showTooltip && (
        <Tooltip
          appConfig={appConfig}
          onClose={toggleTooltip}
          onUpdateAppConfig={onAppConfigUpdate}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
          style={sideMenu}
          addNewCustomNode={addNewCustomNode}
        />
      )}

      <Handle 
        type="source" 
        position="right" 
        id="right" 
        style={{ top: '50%', background: '#555' }} 
      />
      <Handle 
        type="target" 
        position="left" 
        id="left" 
        style={{ top: '50%', background: '#555' }} 
      />
    </div>
  );
};

export default CustomNode;
