import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { RadioGroup } from '@headlessui/react';
import { ChromePicker } from 'react-color'; // Add the color picker from react-color
import { IconMap } from '../../config/IconMap';

// Helper function to combine CSS class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ColorSquares = ({ primary, secondary, accent, onColorChange }) => (
    <div className="flex items-center space-x-2">
        {/* Primary Color Picker */}
        <div 
            className="relative group h-8 w-8 rounded-md cursor-pointer"
            style={{ backgroundColor: primary || '#ffffff' }}
            onClick={() => onColorChange('primary')}
        >
            <div className="absolute inset-0 flex items-center justify-center">
                {React.createElement(IconMap['SwatchIcon'], { 
                    className: 'h-5 w-5 text-white opacity-0 group-hover:opacity-100', 
                    'aria-hidden': 'true' 
                })}
            </div>
        </div>

        {/* Secondary Color Picker */}
        <div 
            className="relative group h-8 w-8 rounded-md cursor-pointer"
            style={{ backgroundColor: secondary || '#ffffff' }}
            onClick={() => onColorChange('secondary')}
        >
            <div className="absolute inset-0 flex items-center justify-center">
                {React.createElement(IconMap['SwatchIcon'], { 
                    className: 'h-5 w-5 text-white opacity-0 group-hover:opacity-100', 
                    'aria-hidden': 'true' 
                })}
            </div>
        </div>

        {/* Accent Color Picker */}
        <div 
            className="relative group h-8 w-8 rounded-md cursor-pointer"
            style={{ backgroundColor: accent || '#ffffff' }}
            onClick={() => onColorChange('accent')}
        >
            <div className="absolute inset-0 flex items-center justify-center">
                {React.createElement(IconMap['SwatchIcon'], { 
                    className: 'h-5 w-5 text-white opacity-0 group-hover:opacity-100', 
                    'aria-hidden': 'true' 
                })}
            </div>
        </div>
    </div>
);


// Main StyleGuide component
function StyleGuide({ onStyleGuideChange, currentStyleGuide }) {
    const [styleGuides, setStyleGuides] = useState([]);
    const [selectedStyleGuide, setSelectedStyleGuide] = useState('');
    const [styleGuidesLoaded, setStyleGuidesLoaded] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState({ field: '', visible: false });
    const [tempColors, setTempColors] = useState({ primary: '', secondary: '', accent: '' });
    const colorPickerRef = useRef(null);

    useEffect(() => {
        const authToken = Cookies.get('auth_token');
        fetch('/api/get-styles', {
            headers: { 'Authorization': `Token ${authToken}` },
        })
        .then(response => response.json())
        .then(data => {
            setStyleGuides(data);
            setStyleGuidesLoaded(true);
        })
        .catch(error => console.error('Error fetching style guides:', error));
    }, []);

    useEffect(() => {
        if (styleGuidesLoaded && styleGuides.length > 0) {
            let initialStyleGuide = styleGuides[0]; 
            if (currentStyleGuide && currentStyleGuide.uid) {
                initialStyleGuide = styleGuides.find(sg => sg.uid === currentStyleGuide.uid) || initialStyleGuide;
            }

            setSelectedStyleGuide(initialStyleGuide.uid);
            setTempColors({
                primary: initialStyleGuide.primary || '#ffffff',
                secondary: initialStyleGuide.secondary || '#ffffff',
                accent: initialStyleGuide.accent || '#ffffff',
            });
        }
    }, [styleGuides, currentStyleGuide, styleGuidesLoaded]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPicker({ field: '', visible: false });
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleStyleGuideSelection = (selectedUID) => {
        if (selectedUID !== selectedStyleGuide) {
            setSelectedStyleGuide(selectedUID);
            const selectedSG = styleGuides.find(sg => sg.uid === selectedUID);
            if (selectedSG) {
                onStyleGuideChange(selectedSG);
                setTempColors({
                    primary: selectedSG.primary || '#ffffff',
                    secondary: selectedSG.secondary || '#ffffff',
                    accent: selectedSG.accent || '#ffffff',
                });
            }
        }
    };

    const handleColorChange = (color, field) => {
        setTempColors(prev => ({ ...prev, [field]: color.hex }));
        const updatedStyleGuide = styleGuides.find(sg => sg.uid === selectedStyleGuide);
        if (updatedStyleGuide) {
            updatedStyleGuide[field] = color.hex;
            onStyleGuideChange(updatedStyleGuide);
        }
    };

    const toggleColorPicker = (field) => {
        setShowColorPicker(prev => ({
            field: field,
            visible: !prev.visible || prev.field !== field,
        }));
    };

    return (
        <div className='px-6 py-6'>
            {styleGuides.length > 0 && (
                <RadioGroup value={selectedStyleGuide} onChange={handleStyleGuideSelection}>
                    <RadioGroup.Label className="text-sm font-medium text-gray-900">Select a style guide</RadioGroup.Label>
                    <div className="mt-2 grid gap-y-4">
                        {styleGuides.map(sg => (
                            <RadioGroup.Option
                                key={sg.uid}
                                value={sg.uid}
                                className={({ checked }) =>
                                    classNames(
                                        checked ? 'border-indigo-500' : 'border-gray-300',
                                        'flex cursor-pointer rounded-lg border-2 p-4'
                                    )
                                }
                            >
                                {({ checked }) => (
                                    <>
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium text-gray-900">{sg.title}</span>
                                                <span className="text-sm text-gray-500">{sg.description}</span>
                                            </div>
                                            <ColorSquares
                                                primary={tempColors.primary}
                                                secondary={tempColors.secondary}
                                                accent={tempColors.accent}
                                                onColorChange={toggleColorPicker}
                                            />
                                        </div>
                                        {showColorPicker.visible && showColorPicker.field && (
                                            <div ref={colorPickerRef} className="absolute z-50 mt-4">
                                                <ChromePicker
                                                    color={tempColors[showColorPicker.field]}
                                                    onChangeComplete={(color) =>
                                                        handleColorChange(color, showColorPicker.field)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            )}
        </div>
    );
}

export default StyleGuide;