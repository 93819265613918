import React, { useState, useEffect } from 'react';
import SubRouteDisclosureButton from '../components/SubRouteDisclosureButton/SubRouteDisclosureButton';
import { marked } from 'marked';
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import SaasMenu from '../components/SaasMenu/SaasMenu';

export default function PageLayout({ previewMode = false, config }) {

  const { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [styleGuide, setStyleGuide] = useState(null);

  const [showBackButton, setShowBackButton] = useState(true);

  // Helper function to process markdown content
  const processMarkdownContent = (content) => {
    if (Array.isArray(content)) {
      // Join array elements with two newlines (if content is an array)
      return content.map(item => item.value).join('\n\n');
    }
    return content || ''; // Return content as is if it's already a string
  };

  // Function to convert Markdown string to HTML using `marked` library
  const createMarkup = () => {
    const markdownContent = processMarkdownContent(pageData.config || config?.markdownContent);
    return { __html: marked(markdownContent) };
  };

  // Fetch data when not in previewMode
  useEffect(() => {
    if (!previewMode) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/page/${uid}`);
          const data = await response.json();
          console.log(data);
          
          setPageData(data);
          setLoading(false);
          
          if (data.style_guide) {
            setStyleGuide(data.style_guide);
          } 

        } catch (error) {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setPageData(config);
      setLoading(false);
    }
  }, [previewMode, config]);

  return (
    <>
      { }
      <div className="min-h-full">
      <div className={"pb-32 headerContainer " + (styleGuide ? "bg-transparent" : "bg-gray-400")} 
          style={{ backgroundColor: styleGuide?.primary }}>
          <SaasMenu showBackButton={showBackButton} />
        </div>
        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 mt-4">
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {loading ? (
                  <>
                    <div className="mt-4 mx-4 overflow-hidden rounded-lg">
                      <Skeleton className="my-2" count={1} height={40} width={250} />
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                    </div>

                    <section className="mt-4 mx-4 overflow-hidden rounded-lg" >
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                      <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={100} />
                    </section>
                  </>
                ) : (
                  <>
                    <h4 className="sr-only">Now viewing: {pageData.title || config?.pageTitle}</h4>
                    <h1 className="text-2xl font-bold tracking-tight text-white px-4">{pageData.title || config?.pageTitle}</h1>
                    <p className="text-base text-white px-4">{pageData.description || config?.pageDescription}</p>
                    <div className="mt-4 overflow-hidden bg-gray-50 rounded-lg px-6 pb-6">
                      <div className="markdown-content" dangerouslySetInnerHTML={createMarkup()} />
                      {(pageData.action_enabled || config?.actionEnabled) && (
                        <Link
                          to={pageData.action_str || config?.actionStr}
                          className="bg-gray-400 text-white py-3 mt-12 px-6 shadow-lg hover:bg-gray-500 transition duration-300 block w-full mx-auto text-center rounded-lg"
                          type="button">
                          {pageData.action_description || config?.actionDescription || 'Learn more'}
                        </Link>
                      )}
                    </div></>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
