import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';

export default function ReceiptModal({ isOpen, closeModal, receiptData }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Disable background scroll when the modal is open
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        setIsVisible(true);
      }, 10);
    } else {
      // Re-enable background scroll when the modal is closed
      document.body.style.overflow = '';

      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-end">
      
      <div
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={closeModal}
      ></div>

      <div
        className={`bg-white w-full md:w-1/2 h-[90%] rounded-t-2xl relative z-50 transform transition-transform duration-300 ${isVisible ? 'translate-y-0 overflow-y-auto' : 'translate-y-full'}`}
      >
        <div className="p-4 border-b flex items-center justify-center">
          <button
            onClick={closeModal}
            className="absolute top-3 left-3 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
          >
            {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
          </button>
          <h2 className="font-semibold">Receipt</h2>
        </div>

        <div className="p-4 overflow-y-auto">
          {receiptData ? (
            <>
              <div className="mb-4">
                <p className="font-semibold text-lg">Receipt ID: {receiptData.id}</p>
                <p className="text-gray-500">Date: {receiptData.date}</p>
              </div>

              <div className="mt-4 p-4 rounded-md bg-gray-50">
                <div className="text-base">
                  {/* Total */}
                  <div className="flex justify-between items-center mb-2">
                    <p className="font-semibold">Total:</p>
                    <p>${receiptData.total}</p>
                  </div>

                  {/* GST */}
                  <div className="flex justify-between items-center">
                    <p className="font-semibold">GST (10%):</p>
                    <p>${receiptData.gst}</p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-semibold">Items</h3>
                <ul>
                  {receiptData.items.map((item, index) => (
                    <li key={index} className="flex justify-between mb-2">
                      <div>
                        <p className="font-medium">{item.title}</p>
                        <p className="text-sm text-gray-500">Price: ${item.price} x {item.quantity}</p>
                      </div>
                      <p className="font-semibold">${item.total}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <p className="text-center text-gray-500">No receipt data available.</p>
          )}
        </div>
      </div>
    </div>
  );
}
