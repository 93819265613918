import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';
import ImageUploader from '../ImageUploader/ImageUploader';

const colors = [
    { name: 'Default', bgColor: 'bg-indigo-500', selectedColor: 'ring-indigo-500' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500' },
    { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500' },
    { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500' },
]
const colourModes = [
    { id: 'auto', title: 'Automatic' },
    { id: 'light', title: 'Light' },
    { id: 'dark', title: 'Dark' },
]
const findColorObject = (bgColor) => {
    return colors.find(color => color.bgColor === bgColor) || colors[0];
};

export default function AppDetailEditView({ display_title, display_description, onAppNameChange, onAppDescriptionChange, onImageFileChange }) {

    // States
    const [name, setName] = useState(display_title || '');
    const [description, setDescription] = useState(display_description || '');
    const [imageFile, setImageFile] = useState(null);

    // Use useEffect to update state when props change
    useEffect(() => {
        setName(display_title || '');
        setDescription(display_description || '');
    }, [display_title, display_description]);

    // Event handlers for input changes
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        onAppNameChange(newName);
    };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        setDescription(newDescription);
        onAppDescriptionChange(newDescription);
    };

    const handleImageChange = (file) => {
        setImageFile(file);
        onImageFileChange(file); // Pass image file to parent
    };

    return (
        <div className="px-6 py-6">
            <form>
                <div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8">

                        <div className="col-span-full">
                            <label htmlFor="app-title" className="block text-sm font-medium leading-6 text-gray-900">
                                Display title
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">Enter the title for your app.</p>
                            <div className="mt-2">
                                <input
                                    id="app-title"
                                    name="app-title"
                                    type="text"
                                    maxLength={20}
                                    defaultValue={name}
                                    onChange={handleNameChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required
                                />
                                <p className="text-xs italic mt-4">Maximum 20 characters</p>
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                Display description
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-600">This is the description that appears under your app name.</p>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={description}
                                    maxLength={20}
                                    onChange={handleDescriptionChange}
                                    required
                                />
                                <p className="text-xs italic mt-4">Maximum 20 characters</p>
                            </div>
                        </div>

                        {/*<RadioGroup value={selectedColor} onChange={handleThemeChange}>
                            <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Choose a theme color
                            </RadioGroup.Label>
                            <div className="mt-4 flex items-center space-x-3">
                                {colors.map((color) => (
                                    <RadioGroup.Option
                                        key={color.name}
                                        value={color}
                                        className={({ active, checked }) =>
                                            classNames(
                                                'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                                                checked ? color.selectedColor : '',
                                                active && checked ? 'ring ring-offset-1' : '',
                                                !active && checked ? 'ring-2' : ''
                                            )
                                        }
                                    >
                                        <RadioGroup.Label as="span" className="sr-only">
                                            {color.name}
                                        </RadioGroup.Label>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(color.bgColor, 'h-8 w-8 rounded-full border border-black border-opacity-10')}
                                        />
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>*/}

                        {/*<div>
                            <label htmlFor="font-family" className="block text-sm font-medium leading-6 text-gray-900">
                                Font family
                            </label>
                            <select
                                id="font-family"
                                name="font-family"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue="Default"
                            >
                                <option>Default</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="colour-mode" className="block text-sm font-medium leading-6 text-gray-900">
                                Colour modes (future)
                            </label>
                            <fieldset className="mt-4">
                                <legend className="sr-only">Select your colour preference</legend>
                                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                    {colourModes.map((colourMode) => (
                                        <div key={colourMode.id} className="flex items-center">
                                            <input
                                                id={colourMode.id}
                                                name="colour-mode"
                                                type="radio"
                                                defaultChecked={colourMode.id === 'email'}
                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <label htmlFor={colourMode.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                {colourMode.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </div>*/}

                        {/* Image Upload */}
                        <ImageUploader
                            accept="image/*"
                            imageFile={imageFile}
                            setImageFile={handleImageChange}
                            label="App Background Image"
                        />
                        
                    </div>
                </div>
            </form>
        </div>
    )
}
