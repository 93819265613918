import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

/* Components */
import { IconMap } from '../config/IconMap';
import { editor_metadata } from '../config/config';
import UpgradeAccount from '../components/UpgradeAccount/UpgradeAccount';
import ProductGrid from '../components/ProductGrid/ProductGrid';
import Notification from '../components/Notifications/Notifications';

export default function VendorProfile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const authToken = Cookies.get('auth_token');

    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    // Modified button code with iPad support
    const handleStripeDashboardRedirect = async () => {
        try {
            const response = await axios.get('/api/financials', {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            });
            const stripeDashboardUrl = response.data.url;

            // iOS/iPadOS detection
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

            if (isIOS) {
                // Direct navigation for iOS
                window.location.href = stripeDashboardUrl;
            } else {
                // Popup for desktop
                const popup = window.open(stripeDashboardUrl, '_blank');
                if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                    // Fallback if popup is blocked
                    window.location.href = stripeDashboardUrl;
                }
            }
        } catch (error) {
            console.error("Error fetching Stripe dashboard link:", error);
            alert("Could not redirect to Stripe dashboard. Please try again later.");
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (!authToken) {
                    throw new Error('No authentication token found');
                }
                const response = await axios.get('/api/vendor-profile', {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                });
                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setLoading(false);
            }
        };
        fetchProfileData();
    }, [authToken]);

    // Fetch the transactions once the profile is available and vendor_lvl is 2
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                if (profile && profile.vendor_level === 2) {
                    setLoadingTransactions(true);
                    const response = await axios.get('/api/recent-transactions', {
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    });
                    setTransactions(response.data.charges);
                    setLoadingTransactions(false);
                }
            } catch (error) {
                console.error("Error fetching recent transactions:", error);
                setLoadingTransactions(false);
            }
        };

        if (profile && profile.vendor_level === 2) {
            fetchTransactions();
        }
    }, [profile, authToken]);

    if (loading) return <p>Loading profile...</p>;

    // Skeleton loader for transactions
    const SkeletonTransaction = () => (
        <li className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 animate-pulse">
            <div className="flex min-w-0 gap-x-4">
                <div className="h-12 w-12 flex-none rounded-full bg-gray-200" />
                <div className="min-w-0 flex-auto space-y-2">
                    <div className="h-4 bg-gray-200 rounded w-36"></div>
                    <div className="h-4 bg-gray-200 rounded w-24"></div>
                </div>
            </div>
        </li>
    );

    // Status Color Coding
    const getStatusClass = (status) => {
        switch (status) {
            case 'succeeded':
                return 'text-green-600';
            case 'pending':
                return 'text-yellow-600';
            case 'failed':
                return 'text-red-600';
            default:
                return 'text-gray-500';
        }
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="flex flex-col lg:flex-row px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                    {profile.show_upgrade_form ? (
                        <div className="lg:w-2/3">
                            <UpgradeAccount context={editor_metadata.PRODUCT_EDIT} />
                        </div>
                    ) : (
                        <>
                            <div className="lg:w-2/3 lg:pr-6">
                                <ProductGrid triggerNotification={triggerNotification} />
                                <Notification show={showNotification} />
                            </div>
                            
                            {/* Right Column: Embedded Stripe Elements (1/3 width) */}
                            <div className="lg:w-1/3 lg:pl-6 mt-4 lg:mt-0">
                                <h2 className="text-base font-semibold text-gray-900 mb-4">
                                    Recent Transactions
                                </h2>
                                <div className="bg-white sm:rounded-lg">
                                    {loadingTransactions ? (
                                        <ul role="list" className="divide-y divide-gray-100">
                                            {/* Render 5 skeletons */}
                                            {[...Array(5)].map((_, index) => (
                                                <SkeletonTransaction key={index} />
                                            ))}
                                        </ul>
                                    ) : (
                                        <ul role="list" className="divide-y divide-gray-100">
                                            {transactions.length > 0 ? (
                                                transactions.map((transaction, index) => (
                                                    <li key={index} className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6">
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                                    Amount: ${(transaction.amount / 100).toFixed(2)}
                                                                </p>
                                                                <p className={`mt-1 flex text-xs leading-5 capitalize ${getStatusClass(transaction.status)}`}>
                                                                    {transaction.status}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex shrink-0 items-center gap-x-4">
                                                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                                                <p className="text-sm leading-6 text-gray-900">Transaction</p>
                                                                <p className="mt-1 text-xs leading-5 text-gray-500">
                                                                    <time dateTime={new Date(transaction.created * 1000).toISOString()}>
                                                                        {new Date(transaction.created * 1000).toLocaleString()}
                                                                    </time>
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <p>No recent transactions found.</p>
                                            )}
                                        </ul>
                                    )}
                                </div>

                                {/* Updated button styles for touch devices */}
                                <div className="mt-6">
                                    <button
                                        className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm 
                                                    active:bg-indigo-700 
                                                    touch-manipulation 
                                                    -webkit-tap-highlight-color-transparent"
                                        onClick={handleStripeDashboardRedirect}
                                        role="button"
                                        aria-label="Open financial dashboard"
                                    >
                                        Access financial dashboard
                                        {/* Adding the external link icon */}
                                        {React.createElement(
                                            IconMap['ArrowTopRightOnSquareIcon'],
                                            {
                                                className: 'ml-2 h-5 w-5 text-white',
                                                'aria-hidden': 'true'
                                            }
                                        )}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </main>
        </>
    );
}
