import React from 'react';
import { XCircleIcon, ExclamationTriangleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

export default function Alert({ type, message }) {
  
  let IconComponent;
  let iconColor;
  let bgColor;
  let textColor;

  switch (type) {
    case 'error':
      IconComponent = XCircleIcon;
      iconColor = 'text-red-400';
      bgColor = 'bg-red-50';
      textColor = 'text-red-800';
      break;
    case 'info':
      IconComponent = InformationCircleIcon;
      iconColor = 'text-blue-400';
      bgColor = 'bg-blue-50';
      textColor = 'text-blue-800';
      break;
    case 'warning':
      IconComponent = ExclamationTriangleIcon;
      iconColor = 'text-yellow-400';
      bgColor = 'bg-yellow-50';
      textColor = 'text-yellow-700';
      break;
    case 'success':
      IconComponent = CheckCircleIcon;
      iconColor = 'text-green-400';
      bgColor = 'bg-green-50';
      textColor = 'text-green-800';
      break;
    default:
      IconComponent = XCircleIcon;
      iconColor = 'text-red-400';
      bgColor = 'bg-red-50';
      textColor = 'text-red-800';
      break;
  }

  return (
    <div className={`rounded-md p-4 mb-6 ${bgColor}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <IconComponent className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${textColor}`}>{message}</p>
        </div>
      </div>
    </div>
  );
}
