import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import ConfirmationModal from '../ConfirmModal/ConfirmModal';

export default function TableView({ context, data, onAddButtonClick, isLoading, onDeleteSuccess }) {

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(Math.ceil(data.length / itemsPerPage));

  const getButtonClasses = (isDisabled) => {
    return `relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'
      }`;
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, [data]);

  useEffect(() => {
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  }, [searchTerm, data]);

  const handleSearchChange = (e) => {

    const searchQuery = e.target.value;
    setSearchTerm(searchQuery);
    // Filter data based on the search query
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    console.log(filteredData);
    setCurrentPage(1); // Reset to the first page
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const getPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const handlePrevPage = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  const handleDelete = async (itemId) => {
    const authToken = Cookies.get('auth_token');
    try {
      const response = await axios.delete(`/api/delete-asset/${itemId}`, {
        headers: {
          'Authorization': `Token ${authToken}`,
        },
      });

      if (response.status === 204) {
        onDeleteSuccess(itemId);
        setIsModalOpen(false);
      } else {
        console.error('Failed to delete item:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div>
        <div className="sm:flex sm:items-center mt-4">

          <div className="sm:flex-auto">
          </div>

          <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
            <label htmlFor="search" className="sr-only">Search</label>
            <input
              type="text"
              name="search-input"
              id="search-input"
              className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:w-56 sm:text-sm sm:leading-6"
              placeholder="Search"
              onChange={handleSearchChange} // Attach the event handler
            />
          </form>

          {/* Create on desktop */}
          {context.ACTION !== "none" && (
            <div className="mt-4 sm:ml-2 sm:mt-0 sm:flex-none">
              <button onClick={onAddButtonClick} className='w-full hidden md:block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                {context.ACTION}
              </button>
            </div>
          )}

        </div>

        {/* Desktop */}
        <div className="hidden md:block mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block sm:w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">

                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    {context.SCHEMA.column_values.map((object, index) => (
                      <tr key={index}>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          {object.name}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {object.description}
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900">
                          Actions
                        </th>
                      </tr>
                    ))}
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {isLoading ? (
                      <>
                        <tr>
                          <td>
                            <Skeleton className="rounded-lg m-2" count={1} height={25} width={200} />
                          </td>
                          <td>
                            <Skeleton className="rounded-lg m-2" count={1} height={25} width={200} />
                          </td>
                          <td>
                            <Skeleton className="rounded-lg m-2" count={1} height={25} width={200} />
                          </td>
                        </tr>
                      </>
                    ) : (
                      // Render table rows if not loading
                      getPageData().map((object, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {object.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs overflow-hidden text-ellipsis">{object.description}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                            <Link to={`/m/${context.REF}/edit/${object.uid}`} className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                              Edit<span className="sr-only">, {context.TITLE} with the name {object.name}</span>
                            </Link>
                            {context.DELETE_ENABLED === true && (
                              <button
                                onClick={() => handleDeleteClick(object.uid)}
                                className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <nav
                className="flex items-center justify-between px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to <span className="font-medium">{Math.min(currentPage * itemsPerPage, filteredData.length)}</span> of{' '}
                    <span className="font-medium">{filteredData.length}</span> results
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={getButtonClasses(currentPage === 1)}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={getButtonClasses(currentPage === totalPages) + ' ml-2'}
                  >
                    Next
                  </button>
                </div>
              </nav>

              {data.length === 0 && (
                // Display error message when data is empty
                <div className="mt-8 p-4 text-center text-sm rounded-md bg-gray-100">
                  {context.ERRORS.message}
                </div>
              )}

            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className="block md:hidden mt-4">
          {(isLoading ? (
            // Render skeleton loader here
            <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={50} />
          ) : data.length === 0 ? (
            // Display error message when data is empty
            <div className="mt-8 p-4 text-center text-sm rounded-md bg-gray-100">
              {context.ERRORS.message}
            </div>
          ) : (
            <>
            <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
              {getPageData().map((object, index) => (
                <li key={index} className="flex items-center justify-between gap-x-6 py-4 px-4">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">{object.title}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Last modified {object.last_modified_date}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Link to={`/m/${context.REF}/edit/${object.uid}`} className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Edit<span className="sr-only">, {context.TITLE} with the name {object.name}</span>
                    </Link>
                    {context.DELETE_ENABLED === true && (
                      <button
                        onClick={() => handleDeleteClick(object.uid)}
                        className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">
                        Delete
                      </button>
                    )}
                  </div>
                </li>
              ))}
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={getButtonClasses(currentPage === 1)}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={getButtonClasses(currentPage === totalPages) + ' ml-2'}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </ul>
              {context.ACTION !== "none" && (
                <div className="fixed inset-x-0 bottom-0 p-4 md:hidden">
    
                  <button onClick={onAddButtonClick} className='w-full h-11 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                    {context.ACTION}
                  </button>
    
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      {context.DELETE_ENABLED === true && context.DELETE_MESSAGING && (
        <ConfirmationModal
          title={context.DELETE_MESSAGING.title}
          contextMsg={context.DELETE_MESSAGING.contextMsg}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={() => handleDelete(selectedItemId)}
        />
      )}
    </>
  )
}
