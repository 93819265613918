import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';

const ImageUploader = ({ imageFile, setImageFile, accept = "image/*", label = "Upload Image" }) => {
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleImageChange = (e) => {
    const { files } = e.target;
    
    // Debugging logs
    console.log('File input changed:', files);

    if (files && files.length > 0) {
      const file = files[0];
      console.log('File selected:', file);
      
      // File size check (1MB limit)
      if (file.size > 1024 * 1024) {
        setError('File size exceeds 1MB');
        return;
      }

      // Set the image file and preview URL
      setImageFile(file);
      setPreview(URL.createObjectURL(file)); // Create URL for image preview
      setError(null);
    } else {
      console.log('No file selected');
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setPreview(null);
    setError(null);
  };

  useEffect(() => {
    console.log('Image File:', imageFile);
    console.log('Preview URL:', preview);
  }, [imageFile, preview]);

  return (
    <div className="col-span-full">
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
          {label}
        </label>
      </div>
      <div className="sm:col-span-2">
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="relative text-center">
            {!preview ? (
              <>
                {React.createElement(IconMap['PhotoIcon'], { className: 'mx-auto h-12 w-12 text-gray-300', 'aria-hidden': 'true' })}
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="image-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="image-upload"
                      name="image"
                      type="file"
                      accept={accept}
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 1MB</p>
              </>
            ) : (
              <>
              
                <img src={preview} alt="Preview" className="mx-auto h-48 w-48 object-cover rounded-lg" />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="absolute top-4 left-4 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
                >
                  {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
                </button>
              </>
            )}
          </div>
        </div>
        {error && <p className="text-xs text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default ImageUploader;
