import React from 'react';
import { Transition, Dialog } from '@headlessui/react';

export default function SlideOverPanel({ isOpen, onClose, children, position = 'right', fullScreen = false }) {
  const slideStyles = {
    right: {
      enter: "translate-x-full",
      enterTo: "translate-x-0",
      leave: "translate-x-0",
      leaveTo: "translate-x-full"
    },
    left: {
      enter: "-translate-x-full",
      enterTo: "translate-x-0",
      leave: "translate-x-0",
      leaveTo: "-translate-x-full"
    }
  };

  const styles = slideStyles[position];

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={onClose}>
        <Transition.Child
          as="div"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/60" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as="div"
            enter="transition ease-in-out duration-300 transform"
            enterFrom={styles.enter}
            enterTo={styles.enterTo}
            leaveFrom={styles.leave}
            leaveTo={styles.leaveTo}
            className="w-full"
          >
            <Dialog.Panel className={`relative flex backdrop-blur-sm ${
              fullScreen 
                ? 'w-screen min-h-screen' 
                : 'w-full max-w-xs float-right'
            }`}>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
