import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconMap } from '../../config/IconMap';
import { useParams } from 'react-router-dom';

// Additional imports for API calls
import axios from 'axios';
import Cookies from 'js-cookie';

const Tooltip = ({ appConfig, onClose, style, onUpdateAppConfig, onAddItem, onDeleteItem, addNewCustomNode }) => {
    
    const [editableData, setEditableData] = useState({});
    const [apiOptions, setApiOptions] = useState([]);
    const [editingItemId, setEditingItemId] = useState({ parentId: null, childId: null, selectedOption: null });

    const [isExternalUrl, setIsExternalUrl] = useState(false);
    const [externalUrl, setExternalUrl] = useState('');
    const [externalName, setExternalName] = useState('');
    const [externalDescription, setExternalDescription] = useState('');

    const [selectedAsset, setSelectedAsset] = useState(null);

    // Updated toggleExternalUrl function
    const toggleExternalUrl = (checked) => {
        setIsExternalUrl(checked);
        // Don't reset editing state here, as we want to keep the edit state visible
    };

    const fetchOptionsFromApi = async (authToken, uid) => {
        try {
            const response = await axios.get('/api/get-assets', {
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });
    
            // Assuming response.data is an array of assets
            const assets = response.data;
    
            // Separating forms and pages based on asset_type
            const forms = assets.filter(asset => asset.asset_type === 'Form');
            const pages = assets.filter(asset => asset.asset_type === 'Page');
            const products = assets.filter(asset => asset.asset_type === 'Product');
            const apps = assets.filter(asset => asset.asset_type === 'App' && asset.uid !== uid);
            
            // Combine forms, pages, apps, and products into a single array
            const combinedOptions = [...forms, ...pages, ...apps, ...products].map((option, index) => ({
                ...option,
                id: index, 
            }));

            setApiOptions(combinedOptions);
        } catch (error) {
            console.error('Error fetching options from API:', error);
        }
    };

    const authToken = Cookies.get('auth_token');
    const uid = useParams();
    useEffect(() => {
        fetchOptionsFromApi(authToken, uid.uid);
    }, [authToken]);

    useEffect(() => {
        const newEditableData = appConfig.reduce((acc, item) => {
            acc[item.id] = editableData[item.id] || {
                title: item.display_title || '',
                type: item.type || 'action'
            };
            return acc;
        }, {});
        setEditableData(newEditableData);
    }, [appConfig]);

    // Function to handle the title change
    const handleTitleChange = (id, newTitle) => {
        setEditableData(prevData => ({
            ...prevData,
            [id]: { ...prevData[id], title: newTitle }
        }));
    };

    // Function to handle the type change
    const handleTypeChange = (id, newType) => {
        setEditableData(prevData => ({
            ...prevData,
            [id]: { ...prevData[id], type: newType }
        }));
        // Fetch options if the type is 'action' or 'gallery'
        if (newType === 'action' || newType === 'gallery') {
            fetchOptionsFromApi();
        }
    };

    // Function to update the actual appConfig when the user leaves the input field (onBlur)
    const updateItemInConfig = (id) => {
        onUpdateAppConfig(
            appConfig.map(item => {
                if (item.id === id) {
                    return { ...item, display_title: editableData[id].title, type: editableData[id].type };
                }
                return item;
            })
        );
    };

    // Function to delete a child item from an item's schema property
    const deleteChildItem = (parentId, childId) => {
        const updatedAppConfig = appConfig.map(item => {
            if (item.id === parentId) {
                // Filter out the child item from the schema subarray
                const updatedSchema = item.schema.filter(childItem => childItem.id !== childId);
                return { ...item, schema: updatedSchema };
            }
            return item;
        });
        onUpdateAppConfig(updatedAppConfig);
    };

    // Function to handle the drag end event
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(appConfig);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);
        onUpdateAppConfig(reorderedItems);
    };

    if (!Array.isArray(appConfig)) {
        return <div>Loading...</div>;
    }

    // Function to handle selecting an option from the dropdown
    const selectSchemaItem = (parentId, selectedOptionId) => {
        const selectedOption = apiOptions.find(option => option.id.toString() === selectedOptionId);
        if (selectedOption) {
            setEditingItemId(prev => ({ ...prev, parentId, selectedOption }));
        }
    };

    const addNewItem = () => {
        const newItemId = `temp-item-${new Date().getTime()}`;
        onAddItem(newItemId); 
        setEditingItemId({ parentId: newItemId, childId: null });
    };

    const renderSchemaItem = (childItem, parentId) => {
        // Determine if the item is an external link or an API option
        const isExternalLink = childItem.option === 'external';
        return (
            <div className="schema-item p-2 my-2 rounded flex justify-between items-center ounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-indigo-400">
                <div className="pl-1">
                    <p className="text-sm font-medium leading-6 text-gray-900">{childItem.name}</p>
                    <p className="text-sm font-medium leading-6 text-gray-900">{childItem.description}</p>
                    {isExternalLink && (
                        <p className="text-sm leading-6 text-gray-500">{childItem.to}</p>
                    )}
                </div>
                {/* Add a delete button for the child item */}
                <button onClick={() => deleteChildItem(parentId, childItem.id)} className="delete-child-item-button mr-2 rounded-md border-0 py-1 px-1 text-gray-900 bg-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50">
                    {React.createElement(IconMap['XMarkIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
                </button>
            </div>
        );
    };

    const saveNewSchemaItem = (parentId) => {
        let newSchemaItem;
        if (isExternalUrl) {
            newSchemaItem = {
                id: `child-${new Date().getTime()}`,
                name: externalName,
                description: externalDescription,
                to: externalUrl,
                option: 'external'
            };
        } else if (editingItemId.selectedOption && editingItemId.selectedOption.description) {
            // Determine the prefix based on asset_type
            const prefix = editingItemId.selectedOption.asset_type === 'Page' ? '/a/p/' : editingItemId.selectedOption.asset_type === 'Form' ? '/a/f/' : '/a/';
            
            // Check if the selected option is a gallery
            if (editingItemId.selectedOption.asset_type === 'Product') {
                // Get the image URL from input (add logic to handle image input elsewhere in your form)
                const imageUrl = editingItemId.selectedOption.images[0].image_url;  // Assuming `background_image_url` is a state
                newSchemaItem = {
                    id: `child-${new Date().getTime()}`,
                    name: editingItemId.selectedOption.title,
                    description: editingItemId.selectedOption.description,
                    to: editingItemId.selectedOption.uid,  
                    option: 'gallery',                             
                    background_image_url: imageUrl,
                    asset_type: 'Product'                 
                };

            } else {
                    // Handle other asset types like pages and forms
                    newSchemaItem = {
                        id: `child-${new Date().getTime()}`,
                        name: editingItemId.selectedOption.title,
                        description: editingItemId.selectedOption.description,
                        to: prefix + editingItemId.selectedOption.uid, 
                        option: editingItemId.selectedOption.asset_type,
                        asset_type: 'App'                 
                    }
            };
            addNewCustomNode(newSchemaItem.name, newSchemaItem.description, newSchemaItem.asset_type);
        } else {
            console.error('No valid data to save');
            return; // Exit if no valid data
        }

        const updatedAppConfig = appConfig.map(item => {
            if (item.id === parentId) {
                return { ...item, schema: [...item.schema, newSchemaItem] };
            }
            return item;
        });

        onUpdateAppConfig(updatedAppConfig);
        setEditingItemId({ parentId: null, childId: null, selectedOption: null });
        setIsExternalUrl(false);
        setExternalUrl('');
        setExternalName('');
        setExternalDescription('');
    };

    const saveEditedItem = (parentId, childId, selectedOption) => {
        // Logic for saving a new child item
        if (childId === null) {
            const newChild = {
                id: `child-${new Date().getTime()}`,
                name: selectedOption.name,
                description: selectedOption.description,
                // Include other properties from selectedOption if necessary
            };
            const updatedAppConfig = appConfig.map(item => {
                if (item.id === parentId) {
                    return { ...item, schema: [...item.schema, newChild] };
                }
                return item;
            });
            onUpdateAppConfig(updatedAppConfig);
        } else {
            // Logic for updating an existing child item
            const updatedAppConfig = appConfig.map(item => {
                if (item.id === parentId) {
                    const updatedSchema = item.schema.map(child => {
                        if (child.id === childId) {
                            return { ...child, ...selectedOption };
                        }
                        return child;
                    });
                    return { ...item, schema: updatedSchema };
                }
                return item;
            });
            onUpdateAppConfig(updatedAppConfig);
        }

        // Reset the editing state
        setEditingItemId(null);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableAppConfig">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={style}
                        className="tooltip bg-gray-200 rounded-md border border-gray-300 p-2"
                    >
                        { appConfig.map((item, index) => {
                            if (!editableData[item.id]) {
                                return null; // Placeholder for items without editable data
                            }
                            return (
                                <Draggable key={item.id} draggableId={`group-${item.id}`} index={index}>
                                    {(provided) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            className="config-item p-1 mb-2 bg-gray-50 rounded shadow cursor-grab"
                                        >
                                            {/* Draggable title bar */}
                                            <div className="flex justify-between items-center p-2">
                                                <input
                                                    type="text"
                                                    className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={editableData[item.id].title}
                                                    onChange={(e) => handleTitleChange(item.id, e.target.value)}
                                                    onBlur={() => updateItemInConfig(item.id)}
                                                />
                                                <select
                                                    className="ml-2 bg-gray-50 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={editableData[item.id].type}
                                                    onChange={(e) => handleTypeChange(item.id, e.target.value)}
                                                    onBlur={() => updateItemInConfig(item.id)}
                                                >
                                                    <option value="action">Navigation</option>
                                                    <option value="gallery">Gallery</option>
                                                </select>
                                                <button onClick={() => onDeleteItem(item.id)} className="delete-item-button ml-2 rounded-md border-0 py-1 px-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 hover:bg-gray-50">
                                                    {React.createElement(IconMap['XMarkIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
                                                </button>
                                            </div>

                                            {/* Non-draggable child items */}
                                            <div className="child-items mx-2 my-2">
                                                {item.schema && item.schema.map((childItem, childIndex) => (
                                                    renderSchemaItem(childItem, item.id)
                                                ))}

                                                {editingItemId && editingItemId.parentId === item.id && !editingItemId.childId ? (
                                                    <div className="new-schema-edit-state my-2 bg-gray-100 p-4">

                                                        {/* Asset selection dropdown */}
                                                        {!isExternalUrl && (
                                                            <div>
                                                                <label htmlFor="select-asset" className='block text-sm mb-2 font-medium leading-6 text-gray-900'>Available assets</label>
                                                                <select
                                                                    id="select-asset"
                                                                    onChange={(e) => selectSchemaItem(item.id, e.target.value)}
                                                                    disabled={isExternalUrl}
                                                                    className="mb-1.5 bg-gray-50 rounded-md w-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                >
                                                                    <option value="" disabled selected>Select an asset</option>
                                                                    {apiOptions.map((option, index) => (
                                                                        <option key={index} value={option.id.toString()}>{option.title} ({option.asset_type})</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}

                                                        {/* Description input for selected asset */}
                                                        {!isExternalUrl && editingItemId.selectedOption && (
                                                            <div className="my-2">
                                                                <label htmlFor="asset-description" className='block text-sm mb-2 font-medium leading-6 text-gray-900'>
                                                                    Description
                                                                </label>
                                                                <input
                                                                    id="asset-description"
                                                                    type="text"
                                                                    placeholder="Enter description for the asset"
                                                                    value={editingItemId.selectedOption.description || ''}
                                                                    onChange={(e) => setEditingItemId(prev => ({ ...prev, selectedOption: { ...prev.selectedOption, description: e.target.value } }))}
                                                                    required
                                                                    className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Checkbox for external URL */}
                                                        <div>
                                                            <input
                                                                id="select-external"
                                                                type="checkbox"
                                                                checked={isExternalUrl}
                                                                onChange={(e) => toggleExternalUrl(e.target.checked)}
                                                                className='mr-2'
                                                            />
                                                            <label htmlFor="select-external" className='text-sm font-medium leading-6 text-gray-900'>
                                                                Use external URL instead
                                                            </label>
                                                        </div>

                                                        {isExternalUrl && (
                                                            <>
                                                                <div className="mb-2 mt-4">
                                                                    <label htmlFor="enter-title" className='block text-sm mb-2 font-medium leading-6 text-gray-900'>
                                                                        Display title
                                                                    </label>
                                                                    <input
                                                                        id="enter-title"
                                                                        type="text"
                                                                        placeholder="Enter display title"
                                                                        required
                                                                        value={externalName}
                                                                        onChange={(e) => setExternalName(e.target.value)}
                                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" // Add styling classes
                                                                    />
                                                                </div>
                                                                <div className="my-2">
                                                                    <label htmlFor="enter-description" className='block text-sm mb-2 font-medium leading-6 text-gray-900'>
                                                                        Display description
                                                                    </label>
                                                                    <input
                                                                        id="enter-description"
                                                                        type="text"
                                                                        required
                                                                        placeholder="Enter description"
                                                                        value={externalDescription}
                                                                        onChange={(e) => setExternalDescription(e.target.value)}
                                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" // Add styling classes
                                                                    />
                                                                </div>
                                                                <div className="my-2">
                                                                    <label htmlFor="enter-url" className='block text-sm mb-2 font-medium leading-6 text-gray-900'>
                                                                        External URL
                                                                    </label>
                                                                    <input
                                                                        id="enter-url"
                                                                        type="url"
                                                                        required
                                                                        placeholder="Enter external URL"
                                                                        value={externalUrl}
                                                                        onChange={(e) => setExternalUrl(e.target.value)}
                                                                        className="mb-2 rounded-md border-0 w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" // Add styling classes
                                                                    />
                                                                </div>
                                                            </>
                                                        )}

                                                        <button
                                                            onClick={() => saveNewSchemaItem(item.id)}
                                                            disabled={(isExternalUrl && (!externalName || !externalDescription || !externalUrl)) ||
                                                                (!isExternalUrl && editingItemId.selectedOption && !editingItemId.selectedOption.description)}
                                                            className="add-item-button mt-4 w-full rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-700 disabled:bg-indigo-400"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                ) : (
                                                    // Button to add a new schema item
                                                    <button
                                                        onClick={() => setEditingItemId({ parentId: item.id, childId: null })}
                                                        className="add-item-button mt-2 w-full rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-indigo-400"
                                                    >
                                                        Create action
                                                    </button>
                                                )}

                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                        {/* Button to add new item */}
                        <div className="tooltip-actions">
                            <button onClick={addNewItem} className="add-item-button w-full rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                Create section
                            </button>
                        </div>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Tooltip;