import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function UpgradeAccount({ context }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleNextStep = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = Cookies.get('auth_token');
      if (!token) {
        throw new Error('No authentication token found');
      }
      const response = await axios.get('/api/upgrade-merchant', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error during Stripe onboarding:', error);
      setError('Failed to initiate Stripe onboarding. Please try again later.');
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:px-0">
        <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-12">
          {context.TITLE}
        </h1>

        <form className="mt-12">
          <section aria-labelledby="summary-heading" className="mt-10">
            <h2 id="summary-heading" className="sr-only">Details</h2>

            {/* Payment Details */}
            <div className="my-2 col-span-full text-center">
              {context.CONTENT}
            </div>

            <div className="my-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Payment method
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Card processing fee
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr className="hover:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Domestic credit card
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      1.7% + 40¢
                    </td>
                  </tr>
                  <tr className="hover:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      International credit card
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      3.5% + 40¢
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="text-center mt-4 text-sm text-gray-500">
              Learn more about our payment options and fees on our{' '}
              <a href="https://docs.ourapp.studio/products#pricing" target="_blank" className="text-indigo-600 hover:underline">
                pricing page
              </a>
              .
            </p>

            <div className="mt-10">
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <button
                type="button"
                onClick={handleNextStep}
                disabled={loading}
                className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                {loading ? 'Redirecting...' : 'Get started'}
              </button>
            </div>

          </section>
        </form>
      </div>
    </div>
  );
}