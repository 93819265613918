import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

/* Components */
import DetailRows from '../components/DetailRows/DetailRows';

export default function Settings({ context }) {

    const [userData, setUserData] = useState(null);
    const authToken = Cookies.get('auth_token');

    useEffect(() => {
        // Make the GET request to fetch user data from Django
        const fetchUserData = async () => {
            try {
                const response = await fetch('/api/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${authToken}`, // Replace with your authentication token
                    },
                });

                if (response.ok) {
                    // Request was successful, parse the response as JSON
                    const data = await response.json();
                    setUserData(data); // Update the state with user data
                } else {
                    // Handle errors here
                    console.error('Request failed with status:', response.status);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };
        fetchUserData();
    }, []);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {userData ? ( // Conditional rendering to check if userData is loaded
                            <>
                                <h1 className="text-base font-semibold text-gray-900 dark:text-slate-100">
                                    Hey {userData.first_name} 👋
                                </h1>

                                <div className='grid grid-cols-1 gap-5 sm:grid-cols-1 xl:grid-cols-1'>
                                    <DetailRows context={context} userData={userData} />
                                </div>
                            </>
                        ) : (
                            <p className="text-gray-900 dark:text-slate-100">Loading user data...</p> // Fallback content while data is being fetched
                        )}
                    </div>
                </div>
            </main>
        </>
    )
}