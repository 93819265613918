import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function PaymentModal({ status, onClose }) {  // Accept status as a prop
  const [open, setOpen] = useState(true);

  const isSuccess = status === 'success';  // Determine success or failure based on the prop

  return (
    <Dialog open={open} onClose={() => {
      setOpen(false);
      onClose();  
    }} className="relative z-10">
      <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300" />
      
      {/* Receipt-like container */}
      <div className="fixed inset-0 z-10 flex justify-center items-center">
        <div className="w-full max-w-xs sm:max-w-sm">
          <DialogPanel className="bg-white text-gray-800 border-2 border-gray-300 shadow-md rounded-lg p-5">
            
            {/* Header: Status Icon */}
            <div className="flex justify-center items-center">
              <div className={`h-12 w-12 rounded-full flex items-center justify-center mb-4 ${isSuccess ? 'bg-green-100' : 'bg-red-100'}`}>
                {isSuccess ? (
                  <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                ) : (
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                )}
              </div>
            </div>

            {/* Title: Success/Fail */}
            <DialogTitle as="h3" className="text-center text-xl font-bold mb-2">
              {isSuccess ? 'Payment successful' : 'Payment failed'}
            </DialogTitle>
            
            {/* Description: Info about the payment */}
            <div className="text-center text-sm mb-4">
              {isSuccess
                ? 'Thank you for your payment. Your transaction was successful.'
                : 'We encountered an issue processing your payment. Please try again.'}
            </div>

            <div className="border-b border-dashed my-4"></div>

            {/* Close button */}
            <div className="mt-4">
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                  onClose();  // Call the onClose prop function
                }}
                className="w-full bg-indigo-600 text-white py-2 rounded-full shadow hover:bg-indigo-500"
              >
                Close
              </button>
            </div>

          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
