import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

/** Components  */
import SubRouteDisclosureButton from '../components/SubRouteDisclosureButton/SubRouteDisclosureButton';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProductLayout({ previewMode = false, config, assetTitle, assetDescription }) {

  const { uid } = useParams();
  const [fadeIn, setFadeIn] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [productData, setProductData] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [styleGuide, setStyleGuide] = useState(null);

  const [featureImage, setFeatureImage] = useState(null);
  const [images, setImages] = useState([]); // New state for images

  // Fetch data when not in previewMode
  useEffect(() => {
    if (!previewMode) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/shop/${uid}`);
          const data = await response.json();
          setProductData(data.config);
          setTitle(data.title);
          setDescription(data.description);
          if (data.style_guide) {
            setStyleGuide(data.style_guide);
          }
          // Set the first image as the feature image if available
          if (data.images && data.images.length > 0) {
            setImages(data.images);
            setFeatureImage(data.images[0].image_url); // Assuming image_url is the property for image URLs
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setProductData(config);
      setTitle(assetTitle);
      setDescription(assetDescription);
      setLoading(false);
    }
  }, [previewMode, config]);

  useEffect(() => {
    if (!loading && !previewMode) {
      setFadeIn(true);
    }
  }, [loading]);

  return (
    <>
      <div className="min-h-full">
        <div className={"pb-32 headerContainer bg-gray-400"}
          style={{
            backgroundColor: styleGuide?.primary,
            backgroundImage: featureImage 
              ? `linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url(${featureImage})`
              : null,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          <SubRouteDisclosureButton previewMode={previewMode} />
        </div>
        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">
            <div>
              <h1 className="text-2xl font-bold tracking-tight text-white px-4">{title}</h1>
              <h4 className="sr-only">Detail view for {assetTitle}</h4>
            </div>
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 mt-4">
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {loading ? (
                  <>
                    <div className="mt-4 mx-4 overflow-hidden rounded-lg">
                      <Skeleton className="my-2" count={1} height={40} width={250} />
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                    </div>

                    <section className="mt-4 mx-4 overflow-hidden rounded-lg" >
                      <Skeleton className="my-2" count={1} height={40} width={150} />
                      <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={100} />
                    </section>
                  </>
                ) : (
                  <>
                    {/* Image gallery */}
                    <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                      <h2 className="sr-only">Images</h2>

                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8">
                        {images.length > 0 && images.map((image) => (
                          <img
                            key={image}
                            src={image.image_url}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
